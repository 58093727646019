<script>
import {mapState} from "vuex";
export default {
  name: "StickyHeader",
  mounted() {
    this.calculatePaddingTop();
  },

  watch: {
    "$screen.width"() {
      this.calculatePaddingTop();
    },

    announcementBarVisible(newVal, oldVal) {
      if (newVal != oldVal) {
        this.calculatePaddingTop();
      }
    },
  },

  computed: {
    ...mapState("menu", ["announcementBarVisible"]),
  },

  methods: {
    calculatePaddingTop() {
      const header = document.querySelector("[data-header]");
      document.body.style.paddingTop = `${header.clientHeight}px`;
    },
  },
};
</script>
