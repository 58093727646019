<template>
  <transition name="fade">
    <div
      v-if="visible"
      data-mobile-cart
      class="fixed bg-white left-0 right-0 bottom-0 top-100 z-50"
      :style="offsetHeaderStyles"
    >
      <div class="relative h-full">
        <template v-if="items.length > 0">
          <ul
            class="px-20 divide-y divide-black overflow-scroll scrollbar-hide h-[calc(100%-133px)]"
            ref="cartBody"
          >
            <li class="py-20" v-for="item in items" :key="item.key">
              <div class="grid grid-cols-4 gap-x-10 mb-10">
                <div class="col-span-1">
                  <div
                    class="relative"
                    :style="{
                      aspectRatio: item.featured_image.aspect_ratio,
                    }"
                  >
                    <a :href="item.url">
                      <img
                        :src="item.featured_image.url"
                        :alt="item.featured_image.alt"
                      />
                    </a>
                  </div>
                </div>

                <div class="col-span-3 text-cart-item-mobile">
                  <a :href="item.url">
                    <p
                      class="text-body-deskop mb-5 pr-10"
                      v-text="productTitle(item)"
                    ></p>
                  </a>
                  <p class="text-gray">
                    <span v-text="formatMoney(item.discounted_price)" />

                    <span
                      v-if="item.original_price > item.discounted_price"
                      class="line-through"
                      v-text="formatMoney(item.original_price)"
                    />
                  </p>
                </div>
              </div>
              <div class="grid grid-cols-4 gap-x-10">
                <div class="col-span-1">
                  <div class="flex-shrink-0 flex w-[62px] mr-50 col-span-1">
                    <button
                      class="text-body-desktop w-10"
                      @click.prevent="update('decrement', item)"
                      aria-label="Remove One"
                    >
                      <span class="sr-only">Remove One</span>
                      <svg
                        width="9"
                        height="1"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path stroke="#000" d="M0 .5h9" />
                      </svg>
                    </button>

                    <span
                      class="text-cart-item-mobile min-w-[50px] text-center mx-5"
                    >
                      {{ item.quantity }}
                    </span>

                    <button
                      class="text-cart-item-mobile w-10"
                      @click.prevent="update('increment', item)"
                      aria-label="Add One"
                    >
                      <span class="sr-only">Add One</span>
                      <svg
                        width="9"
                        height="9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path stroke="#000" d="M0 4.5h9M4.5 9V0" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="col-span-3 flex justify-between">
                  <span
                    class="text-cart-item-mobile underline cursor-pointer"
                    @click="removeItem(item)"
                  >
                    Remove
                  </span>
                  <span
                    class="text-xs mr-2 leading-5 text-h2-mobile text-black"
                    v-text="formatMoney(item.line_price)"
                  ></span>
                </div>
              </div>
            </li>
          </ul>
          <div
            class="p-20 border-t absolute bottom-0 left-0 right-0 bg-white"
            ref="cartFooter"
          >
            <p
              class="text-h2-mobile uppercase mb-5 flex justify-between items-center"
            >
              <span>Subtotal: </span>
              <span class="col-span-1 col-start-5 text-right">{{
                formatMoney(cartData.items_subtotal_price)
              }}</span>
            </p>
            <div
              class="text-gray text-cart-item-mobile"
              v-html="checkoutText"
            ></div>
            <a
              class="block w-full text-center uppercase text-h1-mobile font-bold tracking-product-title bg-black text-white py-[12px] px-10 mt-15"
              href="/checkout"
            >
              Checkout
            </a>
          </div>
        </template>
        <template v-else>
          <div class="pt-80 pb-110">
            <p class="text-center">Your cart is currently empty.</p>
          </div>
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
import bodyLock from "../../mixins/bodyLock";
import {mapActions, mapState} from "vuex";
import {formatMoney} from "@shopify/theme-currency";

export default {
  name: "MobileCart",
  mixins: [bodyLock],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    checkoutText: {
      type: String,
      default: "Shipping + taxes calculated at checkout",
      required: true,
    },
  },

  data: () => ({
    headerOffset: 0,
  }),

  computed: {
    ...mapState("cart", ["cartData", "visible", "loading"]),

    offsetHeaderStyles() {
      if (this.visible) {
        return {
          top: `${this.headerOffset}px`,
        };
      } else {
        return {
          top: `-200vh`,
        };
      }
    },
  },

  watch: {
    visible(newVal) {
      this.setOffsetTopHeight();
    },
  },

  methods: {
    ...mapActions("cart", {
      init: "initCart",
      hide: "hide",
      removeItem: "removeItem",
      updateItem: "updateItem",
    }),

    update(type, item) {
      this.updateItem({
        item: item,
        type: type,
      });
    },

    close() {
      this.hide();
    },

    formatMoney(value) {
      return formatMoney(value, "${{amount}}");
    },

    formatDiscountText({discount_application}) {
      if (discount_application.value_type === "percentage") {
        return `${Number(discount_application.value)}%`;
      } else {
        return `$${Number(discount_application.value)}`;
      }
    },

    productTitle(product) {
      return `${product.product_title}, ${product.variant_title}`;
    },

    setOffsetTopHeight() {
      const header = document.querySelector("[data-header]");
      const abelBar = document.querySelector("[data-abel-bar]");
      this.headerOffset =
        header.getBoundingClientRect().height -
        abelBar.getBoundingClientRect().height;
    },
  },
};
</script>
