<template>
  <div
    class="relative text-caption-mobile text-center pt-5 md:py-15 px-20 lg:px-40 border-b md:flex md:items-center lg:text-caption-desktop bg-white z-40"
    v-if="announcementBarVisible"
  >
    <div
      class="richtext max-w-content mx-auto md:px-20"
      v-if="content"
      v-html="content"
    />
    <button
      class="lg:absolute md:right-20 lg:right-40"
      aria-label="Close Announcement Bar"
      @click="close"
    >
      <svg
        width="16"
        height="17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke="#000"
          d="m3.793 12.402 7.778-7.778M3.982 4.624l7.779 7.778"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
export default {
  name: "AnnouncementBar",
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cookieName: "abel:announcementBar",
      cookie: Boolean(sessionStorage.getItem("abel:announcementBar")),
    };
  },
  created() {
    if (this.cookie) {
      this.hideAnnouncementBar();
    }
  },
  computed: {
    ...mapState("menu", ["announcementBarVisible"]),
  },
  methods: {
    ...mapActions("menu", ["hideAnnouncementBar"]),

    close() {
      sessionStorage.setItem(this.cookieName, true);
      this.hideAnnouncementBar();
    },
  },
};
</script>
