<script>
import {mapState, mapGetters, mapActions} from "vuex";
export default {
  name: "ProductGallery",
  props: {},
  data: () => {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
  render() {
    return this.$slots.default({});
  },
};
</script>
