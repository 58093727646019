<template>
  <transition name="fade">
    <div
      data-cart
      class="hidden lg:block fixed left-0 right-0 bg-white border-b overflow-auto scrollbar-hide lg:max-h-[600px] z-40"
      :style="offsetHeaderStyles"
      ref="cartContainer"
      v-if="visible"
    >
      <div>
        <div class="px-40">
          <template v-if="items.length > 0">
            <div
              ref="cartHeader"
              class="grid grid-cols-12 gap-x-40 text-body-desktop pb-15"
            >
              <div class="col-span-6 xl:col-span-7">
                <p class="uppercase font-bold">Item</p>
              </div>

              <div class="col-span-3 xl:col-span-4">
                <p class="uppercase font-bold">Quantity</p>
              </div>

              <div class="col-span-2 col-start-11 xl:col-span-1">
                <p class="uppercase font-bold text-right">Price</p>
              </div>
            </div>

            <ul
              ref="cartBody"
              class="divide divide-y divide-black overflow-y-scroll overflow-x-hidden scrollbar-hide"
            >
              <li
                class="grid grid-cols-12 py-40 gap-x-40"
                v-for="item in items"
                :key="item.key"
              >
                <div class="col-span-1">
                  <a :href="item.url">
                    <img
                      class=""
                      :src="item.image"
                      :alt="item.featured_image.alt"
                    />
                  </a>
                </div>

                <div class="col-span-5 xl:col-span-6">
                  <a :href="item.url">
                    <p
                      class="text-body-deskop mb-5 pr-10"
                      v-text="productTitle(item)"
                    ></p>
                  </a>
                  <p class="text-gray text-body-desktop">
                    <span v-text="formatMoney(item.discounted_price)" />

                    <span
                      v-if="item.original_price > item.discounted_price"
                      class="line-through"
                      v-text="formatMoney(item.original_price)"
                    />
                  </p>
                </div>

                <div class="col-span-3 xl:col-span-4 flex items-start">
                  <div class="flex-shrink-0 flex w-[62px] mr-50 col-span-1">
                    <button
                      class="text-body-desktop w-10"
                      @click.prevent="update('decrement', item)"
                      aria-label="Remove One"
                    >
                      <span class="sr-only">Remove One</span>
                      <svg
                        width="9"
                        height="1"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path stroke="#000" d="M0 .5h9" />
                      </svg>
                    </button>

                    <span
                      class="text-body-desktop min-w-[50px] text-center mx-5"
                    >
                      {{ item.quantity }}
                    </span>

                    <button
                      class="text-body-desktop w-10"
                      @click.prevent="update('increment', item)"
                      aria-label="Add One"
                    >
                      <span class="sr-only">Add One</span>
                      <svg
                        width="9"
                        height="9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path stroke="#000" d="M0 4.5h9M4.5 9V0" />
                      </svg>
                    </button>
                  </div>

                  <span
                    class="text-body-desktop underline cursor-pointer"
                    @click="removeItem(item)"
                  >
                    Remove
                  </span>
                </div>

                <div class="col-span-2 col-start-11 xl:col-span-1 text-right">
                  <span
                    class="tracking-2p text-xs mr-2 leading-5 text-body-desktop"
                    v-text="formatMoney(item.line_price)"
                  ></span>
                </div>
              </li>
            </ul>
          </template>
          <template v-else>
            <div class="pt-20 pb-110">
              <p class="text-center text-body-mobile lg:text-body-desktop">
                Your cart is currently empty.
              </p>
            </div>
          </template>
        </div>

        <div ref="cartFooter" class="px-40 bg-white" v-if="items.length > 0">
          <div class="grid grid-cols-12 gap-x-40 border-t py-40">
            <div class="col-start-8 col-span-5">
              <p
                class="grid grid-cols-5 text-h2-mobile lg:text-h2-desktop uppercase mb-5"
              >
                <span>Subtotal: </span>
                <span class="col-span-1 col-start-5 text-right">{{
                  formatMoney(cartData.items_subtotal_price)
                }}</span>
              </p>
              <div
                class="text-gray text-body-desktop lg:text-body-desktop"
                v-html="checkoutText"
              ></div>

              <a
                class="block w-full text-center uppercase text-h1-mobile font-bold tracking-product-title bg-black text-white py-[12px] px-10 mt-15"
                href="/checkout"
                >Checkout</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import bodyLock from "../../mixins/bodyLock";
import {mapActions, mapState} from "vuex";
import {formatMoney} from "@shopify/theme-currency";

export default {
  name: "DesktopCart",
  mixins: [bodyLock],
  props: {
    checkoutText: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    headerOffset: 0,
  }),

  computed: {
    ...mapState("cart", ["cartData", "visible", "loading"]),

    offsetHeaderStyles() {
      if (this.visible) {
        return {
          top: `${this.headerOffset}px`,
        };
      } else {
        return {
          top: `-200vh`,
        };
      }
    },
  },

  async beforeCreate() {
    await this.$store.dispatch("cart/initCart");
  },

  watch: {
    "$screen.width"() {
      this.hide();

      if (this.$grid.breakpoint === this.bp) return;
      this.bp = this.$grid.breakpoint;
    },
    visible() {
      this.setOffsetTopHeight();
      // this.setCartBodyHeight();
    },
  },

  methods: {
    ...mapActions("cart", {
      init: "initCart",
      hide: "hide",
      removeItem: "removeItem",
      updateItem: "updateItem",
    }),

    update(type, item) {
      this.updateItem({
        item: item,
        type: type,
      });
    },

    close() {
      this.hide();
    },

    formatMoney(value) {
      return formatMoney(value, "${{amount}}");
    },

    setCartBodyHeight() {
      return false;
      const sections = {
        header: this.$refs.cartHeader.getBoundingClientRect(),
        body: this.$refs.cartBody.getBoundingClientRect(),
        footer: this.$refs.cartFooter.getBoundingClientRect(),
      };

      const heightSum =
        sections.header.height + sections.body.height + sections.footer.height;

      if (heightSum > 605) {
        this.$refs.cartBody.style.height = `${
          sections.body.height - sections.footer.height
        }px`;
      }
      // this.$refs.cartBody.style.paddingBottom = `${sections.footer.height}px`

      // this.$refs.desktopCartBody.style.height = `calc(100vh - ${this.$refs.desktopCartFooter.getBoundingClientRect().height}px)`

      // sections.body.style.height = `{sections.header.height - sections.footer.height}px`

      // const footerDims = this.$refs.cartFooter.getBoundingClientRect();
      // const desktopCartBodyDims =
      //   this.$refs.desktopCartBody.getBoundingClientRect();
      // const desktopFooterdims =
      //   this.$refs.desktopCartFooter.getBoundingClientRect();

      // this.$refs.cartBody.style.height = `calc(100vh - ${
      //   footerDims.height - this.$refs.cartBody.style.paddingBottom
      // }px)`;

      // this.$refs.cartContainer.style.paddingBottom = `${desktopFooterdims.height}px`

      // this.$refs.desktopCartBody.style.height = `calc(100vh - ${
      //   desktopFooterdims.height - 40
      // }px)`;

      // this.$refs.desktopCartBody.style.height = `calc(100vh - ${
      //   desktopFooterdims.height -
      //   this.$refs.desktopCartBody.style.paddingBottom
      // }px))`;
    },

    formatDiscountText({discount_application}) {
      if (discount_application.value_type === "percentage") {
        return `${Number(discount_application.value)}%`;
      } else {
        return `$${Number(discount_application.value)}`;
      }
    },

    productTitle(product) {
      console.log("product:", product);
      if (product.variant_title) {
        return `${product.product_title}, ${product.variant_title}`;
      } else {
        return product.product_title;
      }
    },

    setOffsetTopHeight() {
      const header = document.querySelector("[data-header]");
      this.headerOffset = header.getBoundingClientRect().height;
    },

    parseBody(body) {
      var doc = new DOMParser().parseFromString(body, "text/html");
      return doc.documentElement.textContent;
    },
  },
};
</script>
