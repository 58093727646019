<template>
  <template v-if="$screen.width <= 1023">
    <mobile-cart
      :checkout-text="checkoutText"
      :items="items"
      @close="close"
    ></mobile-cart>
  </template>
  <template v-if="$screen.width >= 1024">
    <desktop-cart :checkout-text="checkoutText" :items="items"></desktop-cart>
  </template>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {formatMoney} from "@shopify/theme-currency";
import MobileCart from "./mobileCart.vue";
import DesktopCart from "./desktopCart.vue";

export default {
  name: "StoreCart",
  props: {
    checkoutText: {
      type: String,
      default: "Shipping + taxes calculated at checkout",
      required: true,
    },
  },
  components: {
    MobileCart,
    DesktopCart,
  },
  data: () => ({
    headerOffset: 0,
  }),

  async beforeCreate() {
    await this.$store.dispatch("cart/initCart");
  },

  watch: {
    "$screen.width"() {
      this.hide();

      if (this.$grid.breakpoint === this.bp) return;
      this.bp = this.$grid.breakpoint;
    },
    visible() {
      this.setOffsetTopHeight();
      this.setCartBodyHeight();
    },
  },

  methods: {
    ...mapActions("cart", {
      init: "initCart",
      hide: "hide",
      removeItem: "removeItem",
      updateItem: "updateItem",
    }),

    update(type, item) {
      this.updateItem({
        item: item,
        type: type,
      });
    },

    close() {
      this.hide();
    },

    formatMoney(value) {
      return formatMoney(value, "${{amount}}");
    },

    setCartBodyHeight() {
      return false;
      const sections = {
        header: this.$refs.cartHeader.getBoundingClientRect(),
        body: this.$refs.cartBody.getBoundingClientRect(),
        footer: this.$refs.cartFooter.getBoundingClientRect(),
      };

      const heightSum =
        sections.header.height + sections.body.height + sections.footer.height;

      if (heightSum > 605) {
        this.$refs.cartBody.style.height = `${
          sections.body.height - sections.footer.height
        }px`;
      }
      // this.$refs.cartBody.style.paddingBottom = `${sections.footer.height}px`

      // this.$refs.desktopCartBody.style.height = `calc(100vh - ${this.$refs.desktopCartFooter.getBoundingClientRect().height}px)`

      // sections.body.style.height = `{sections.header.height - sections.footer.height}px`

      // const footerDims = this.$refs.cartFooter.getBoundingClientRect();
      // const desktopCartBodyDims =
      //   this.$refs.desktopCartBody.getBoundingClientRect();
      // const desktopFooterdims =
      //   this.$refs.desktopCartFooter.getBoundingClientRect();

      // this.$refs.cartBody.style.height = `calc(100vh - ${
      //   footerDims.height - this.$refs.cartBody.style.paddingBottom
      // }px)`;

      // this.$refs.cartContainer.style.paddingBottom = `${desktopFooterdims.height}px`

      // this.$refs.desktopCartBody.style.height = `calc(100vh - ${
      //   desktopFooterdims.height - 40
      // }px)`;

      // this.$refs.desktopCartBody.style.height = `calc(100vh - ${
      //   desktopFooterdims.height -
      //   this.$refs.desktopCartBody.style.paddingBottom
      // }px))`;
    },

    formatDiscountText({discount_application}) {
      if (discount_application.value_type === "percentage") {
        return `${Number(discount_application.value)}%`;
      } else {
        return `$${Number(discount_application.value)}`;
      }
    },

    productTitle(product) {
      return `${product.product_title}, ${product.variant_title}`;
    },

    setOffsetTopHeight() {
      const header = document.querySelector("[data-header]");
      this.headerOffset = header.getBoundingClientRect().height;
    },
  },

  computed: {
    ...mapState("cart", ["cartData", "visible", "loading"]),
    items() {
      return this.cartData ? this.cartData.items : [];
    },

    offsetHeaderStyles() {
      if (this.visible) {
        return {
          top: `${this.headerOffset}px`,
        };
      } else {
        return {
          top: `-200vh`,
        };
      }
    },
  },
};
</script>
