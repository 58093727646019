<script>
import {mapState, mapGetters, mapActions} from "vuex";
import bodyLock from "../../mixins/bodyLock";
export default {
  name: "MenuActionsDisplay",
  mixins: [bodyLock],
  props: {},
  data: () => ({
    navBg: null,
  }),

  mounted() {
    this.navBg = document.querySelector("[nav-bg]");
  },

  watch: {
    menuPanelIsVisible(newVal) {
      if (newVal) {
        this.lockScreen();
        this.showNavBg();
      } else {
        this.unlockScreen();
        this.hideNavBg();
      }
    },

    visiblePanel(newVal) {
      this.changePageTitleDisplay(newVal);
    },
  },

  computed: {
    ...mapState("menu", {
      menuVisible: "visible",
    }),
    ...mapState("cart", {
      cartVisible: "visible",
    }),

    menuPanelIsVisible() {
      return this.cartVisible || this.menuVisible;
    },

    visiblePanel() {
      return {
        cart: this.cartVisible,
        menu: this.menuVisible,
      };
    },
  },

  methods: {
    ...mapActions("menu", {
      menuToggle: "toggle",
      menuHide: "hide",
      menuShow: "show",
    }),

    ...mapActions("cart", {
      cartToggle: "toggle",
      cartHide: "hide",
    }),

    showNavBg() {
      this.navBg.classList.remove("pointer-events-none", "bg-opacity-0");
      this.navBg.classList.add("pointer-events-auto", "bg-opacity-50");
    },

    hideNavBg() {
      this.navBg.classList.remove("pointer-events-auto", "bg-opacity-50");
      this.navBg.classList.add("pointer-events-none", "bg-opacity-0");
    },

    changePageTitleDisplay({cart, menu}) {
      const pageTitles = [...document.querySelectorAll("[data-page-text]")];

      if (menu) {
        pageTitles.forEach(title => {
          title.classList.remove("opacity-100");
          title.classList.add("opacity-0");
        });
      }

      if (!menu) {
        pageTitles.forEach(title => {
          title.classList.remove("opacity-0");
          title.classList.add("opacity-100");
        });
      }
    },
  },

  render() {
    return this.$slots.default({
      menuPanelIsVisible: this.menuPanelIsVisible,
      visiblePanel: this.visiblePanel,
    });
  },
};
</script>
