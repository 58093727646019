<script>
import {mapState, mapActions} from "vuex";
import bodyLock from "../../mixins/bodyLock";
export default {
  name: "CloseToggle",
  mixins: [bodyLock],
  props: {},
  data: () => {
    return {
      pageTitles: [],
    };
  },

  computed: {
    ...mapState("cart", {
      cartVisible: "visible",
    }),
    ...mapState("menu", {
      menuVisible: "visible",
    }),

    closeIsVisible() {
      return this.cartVisible || this.menuVisible;
    },
  },

  methods: {
    ...mapActions("cart", {
      cartToggle: "toggle",
      cartShow: "show",
      cartHide: "hide",
    }),

    ...mapActions("menu", {
      menuHide: "hide",
    }),

    closeAction() {
      if (this.cartVisible) {
        this.cartHide();
      }

      if (this.menuVisible) {
        this.menuHide();
      }
    },
  },

  render() {
    return this.$slots.default({
      closeAction: this.closeAction,
      closeIsVisible: this.closeIsVisible,
    });
  },
};
</script>
