<script>
import {mapState, mapGetters, mapActions} from "vuex";
export default {
  name: "ElementClickAway",
  props: {},
  data: () => {
    return {};
  },
  computed: {
    ...mapState("menu", {
      menuVisible: "visible",
    }),
    ...mapState("cart", {
      cartVisible: "visible",
    }),
  },

  mounted() {
    document.addEventListener("click", this.checkClickedElement);
  },
  beforeMount() {
    document.removeEventListener("click", this.checkClickedElement);
  },
  methods: {
    ...mapActions("menu", {
      menuHide: "hide",
    }),
    ...mapActions("cart", {
      cartHide: "hide",
    }),
    checkClickedElement(evt) {
      if (!this.cartVisible && !this.menuVisible) return false;
      const navBg = document.querySelector("[nav-bg ]");

      if (navBg.contains(evt.target)) {
        this.menuHide();
        this.cartHide();
      }
    },
  },
};
</script>
