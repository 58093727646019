<script>
import {mapState, mapGetters, mapActions} from "vuex";
import bodyLock from "../../mixins/bodyLock";
export default {
  name: "MenuToggle",
  mixins: [bodyLock],
  props: {},
  data: () => {
    return {
      pageTitles: [],
    };
  },

  computed: {
    ...mapState("menu", {
      menuVisible: "visible",
    }),
    ...mapState("cart", {
      cartVisible: "visible",
    }),
  },

  methods: {
    ...mapActions("menu", {
      menuToggle: "toggle",
      menuHide: "hide",
      menuShow: "show",
    }),

    ...mapActions("cart", {
      cartToggle: "toggle",
      cartHide: "hide",
    }),

    toggleMenuDisplay() {
      if (this.menuVisible) {
        this.menuHide();
      } else {
        this.cartHide();
        this.menuShow();
      }
    },
  },

  render() {
    return this.$slots.default({
      menuToggle: this.toggleMenuDisplay,
      menuVisible: this.menuVisible,
      cartVisible: this.cartVisible,
      cartClose: this.cartHide,
    });
  },
};
</script>
