<template>
  <div
    class="relative video-wrap"
    :style="{
      'aspect-ratio': media.aspect_ratio,
    }"
  >
    <video
      ref="video"
      :poster="media.preview_image.src"
      loop
      autoplay
      muted
      preload="auto"
      playsinline="true"
      @loadeddata="loadEvent"
    >
      <source :src="media.sources[1].url" :type="media.sources[1].mime_type" />
    </video>

    <div class="absolute bottom-5 right-5 video-controls text-alto">
      <button
        class="w-45 h-45 flex items-center justify-center"
        @click.prevent="play"
        v-if="!isPlaying"
      >
        <span class="sr-only">Play Video</span>
        <svg
          width="10"
          height="13"
          viewBox="0 0 10 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10 6.35 0 12.703V0l10 6.35Z" fill="currentColor" />
        </svg>
      </button>
      <button
        class="w-45 h-45 flex items-center justify-center"
        @click.prevent="pause"
        v-if="isPlaying"
      >
        <span class="sr-only">Pause Video</span>
        <svg
          width="9"
          height="14"
          viewBox="0 0 9 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fill="currentColor" d="M0 0h3v14H0zm6 0h3v14H6z" />
        </svg>
      </button>
    </div>
  </div>
</template>
<script>
import {mapState, mapGetters, mapActions} from "vuex";
export default {
  name: "VideoPlayer",
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      loaded: false,
      isPlaying: false,
    };
  },

  methods: {
    loadEvent(e) {
      this.isPlaying = true;
    },

    play() {
      if (!this.$refs.video) return false;
      this.$refs.video.play();
      this.isPlaying = true;
    },

    pause() {
      if (!this.$refs.video) return false;
      this.$refs.video.pause();
      this.isPlaying = false;
    },
  },
};
</script>
<style>
.video-wrap .video-controls {
  @apply opacity-0 transition-opacity ease-in-out duration-200;
}

.video-wrap:hover .video-controls {
  @apply opacity-100;
}
</style>
