<script>
import {mapActions, mapState} from "vuex";
import {subscribe} from "klaviyo-subscribe";
import axios from "axios";
/**
 * The only thing required for this to run:
 * input field with the `email`
 */
export default {
  name: "Newsletter",
  props: {
    listId: {
      required: true,
      type: String,
    },
  },
  data: () => ({
    loading: false,
    success: false,
    error: false,
    message: "",
  }),
  methods: {
    submitHandler(e) {
      e.preventDefault();
      const {email} = e.target.elements;
      let formData = new FormData(e.target);

      this.loading = true;
      this.error = false;
      this.success = false;

      const messages = {
        success: "Success!",
        error: "Error!",
      };

      subscribe(this.listId, email.value).then(resp => {
        email.value = "Submitting...";
        if (resp.success) {
          this.success = true;
          setTimeout(() => {
            e.target.reset();
            this.message =
              "Check your inbox to complete your subscription signup";
          }, 600);
        } else {
          this.error = true;
          this.message = resp.errors[0];
          email.value = "";
        }
        this.loading = false;
      });
    },
  },
  render() {
    return this.$slots.default({
      submitHandler: this.submitHandler,
      success: this.success,
      message: this.message,
      loading: this.loading,
    });
  },
};
</script>
