<template>
  <ul
    class="divide-y divide-black border-t border-black border-b"
    v-if="listData && listData.length > 0"
  >
    <li v-for="(item, idx) in listData" :key="item">
      <div>
        <button
          @click.prevent="togglePanel($event, idx)"
          class="w-full text-h2-mobile uppercase flex items-center justify-between tracking-product-title pt-15 pb-15"
        >
          {{ item.title }}
          <span>+</span>
        </button>

        <transition
          name="accordion"
          @enter="start"
          @after-enter="end"
          @before-leave="start"
          @after-leave="end"
        >
          <div v-show="idx === activeIndex">
            <div
              class="text-caption-mobile pt-10 pb-25 richtext"
              v-html="parseBody(item.body)"
            ></div>
          </div>
        </transition>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Accordion",
  props: {
    listData: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    activeIndex: null,
  }),

  mounted() {},

  methods: {
    togglePanel($event, index) {
      if (this.activeIndex === index) {
        this.activeIndex = null;
      } else {
        this.activeIndex = index;
      }
    },

    start(el) {
      el.style.height = `${el.scrollHeight}px`;
    },

    end(el) {
      el.style.height = "";
    },

    parseBody(body) {
      var doc = new DOMParser().parseFromString(body, "text/html");
      return doc.documentElement.textContent;
    },
  },
};
</script>
<style scoped>
.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter-from,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
