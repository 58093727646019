<script>
import {mapState} from "vuex";
export default {
  name: "PdpGalleryOffset",

  mounted() {
    this.calculatePaddingTop();
  },

  watch: {
    "$screen.width"() {
      this.calculatePaddingTop();
    },

    announcementBarVisible() {
      this.calculatePaddingTop();
    },
  },

  computed: {
    ...mapState("menu", ["announcementBarVisible"]),
  },

  methods: {
    calculatePaddingTop() {
      if (this.$screen.width < 1024) return false;
      const header = document.querySelector("[data-header]");
      const headerDims = header.getBoundingClientRect();
      const els = [...document.querySelectorAll("[data-sticky-top]")];

      els.forEach(el => {
        el.style.top = `${headerDims.height + 20}px`;
      });
    },
  },
};
</script>
