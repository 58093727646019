<script>
import {mapState, mapActions} from "vuex";
import productOptions from "../../mixins/productOptions";
export default {
  name: "ProductOptions",
  mixins: [productOptions],
  props: {},
  computed: {
    ...mapState("product", ["selectedVariant", "soldOut"]),
  },

  watch: {
    variantToPurchase(newVal) {
      /**
       * For some reason, the quantity modifications is being added to the variant object immediately
       * So any variant is seen as "selected" even though we do not have real variant data yet.
       */
      this.setVariant(newVal);
      this.renderAffirmWidget(newVal);
    },
  },

  methods: {
    ...mapActions("product", ["setProduct", "setVariant"]),
    renderAffirmWidget(value) {
      if (this.soldOut) return false;
      const affirmElement = document.querySelector("[data-affirm]");

      if (window.affirm?.ui?.refresh && value) {
        affirmElement.classList.remove("hidden");
        affirmElement.setAttribute("data-amount", value.price);

        window.affirm.ui.refresh();
      }

      if (!value) {
        affirmElement.classList.add("hidden");
      }
    },
  },

  mounted() {
    /**
     * The setup in here relies on options to be selected,
     * as the variants are filtered based on those selections.
     * In this case, we preselect a value assuming there is one variant available.
     */
    if (this.eligibleVariants.length === 1) {
      const option = this.productData.options[0];
      this.selectedOptions = [
        {
          type: option.name.toLowerCase(),
          position: option.position,
          value: option.value[0],
        },
      ];
    }
  },

  render() {
    return this.$slots.default({
      selectedVariant: this.selectedVariant,
      findVariantsByOptions: this.findVariantsByOptions,
      options: this.selectedOptions,
      isActiveOption: this.isActiveOption,
      isVisibleOption: this.isVisibleOption,
      stockMessage: this.stockMessage,
      quantityModification: this.quantityModification,
      productQuantity: this.productQuantity,
      soldOut: this.soldOut,
    });
  },
};
</script>
