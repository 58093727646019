<script>
export default {
  name: "NewsletterPopup",
  props: {},
  data: () => {
    return {
      visible: false,
      cookieName: "abel:hideNewsletterPopup",
      popupCookie: Boolean(sessionStorage.getItem("abel:hideNewsletterPopup")),
    };
  },
  created() {
    if (this.popupCookie) {
      this.visible = false;
    } else {
      setTimeout(() => {
        this.visible = true;
      }, 1000);
    }
  },

  methods: {
    hide() {
      this.visible = false;
      sessionStorage.setItem(this.cookieName, true);
    },
  },
  render() {
    return this.$slots.default({
      hide: this.hide,
      visible: this.visible,
    });
  },
};
</script>
