<template>
  <div class="py-20">
    <div class="flex mb-[12px]">
      <div class="w-[62px] mr-[8px] flex-shrink-0">
        <img :src="item.image" :alt="item.featured_image.alt" />
      </div>

      <div class="w-full">
        <!-- title -->
        <h4 class="text-body-mobile mb-5" v-text="productTitle(item)" />
        <p class="text-gray text-body-mobile">
          <span v-text="formatMoney(item.discounted_price)" />

          <span
            v-if="item.original_price > item.discounted_price"
            class="line-through"
            v-text="formatMoney(item.original_price)"
          />
        </p>
      </div>
    </div>
    <div class="flex items-center text-body-mobile">
      <div class="flex-shrink-0 flex w-[62px] mr-[8px] col-span-1">
        <button
          class="text-body-mobile w-10"
          @click.prevent="update('decrement')"
          aria-label="Remove One"
        >
          <span class="sr-only">Remove One</span>
          <svg
            width="9"
            height="1"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path stroke="#000" d="M0 .5h9" />
          </svg>
        </button>

        <span class="text-body-mobile min-w-[25px] text-center mx-5">
          {{ item.quantity }}
        </span>

        <button
          class="text-body-mobile w-10"
          @click.prevent="update('increment')"
          aria-label="Add One"
        >
          <span class="sr-only">Add One</span>
          <svg
            width="9"
            height="9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path stroke="#000" d="M0 4.5h9M4.5 9V0" />
          </svg>
        </button>
      </div>

      <div class="w-full flex items-center justify-between">
        <div class="flex items-end">
          <span
            class="text-body-mobile underline cursor-pointer"
            @click="remove"
          >
            Remove
          </span>
        </div>

        <div class="ml-auto flex flex-col items-end">
          <div>
            <span
              class="text-gray tracking-2p text-xs mr-2 leading-5"
              v-text="formatMoney(item.line_price)"
            ></span>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from "vuex";
import {formatMoney} from "@shopify/theme-currency";
export default {
  name: "CartItem",
  props: {
    item: {
      type: Object,
      default: () => ({}),
      require: true,
    },
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapState("cart", {
      cart: "cartData",
      loading: "loading",
    }),
  },
  mounted() {},
  methods: {
    ...mapActions("cart", ["removeItem", "updateItem"]),
    remove() {
      this.removeItem(this.item);
    },
    update(type) {
      this.updateItem({
        item: this.item,
        type: type,
      });
    },
    formatMoney(value) {
      return formatMoney(value, "${{amount}}");
    },
    hasVariantOrSubscription(item) {
      return item.variant_title || (item.properties && item.properties.details);
    },
    formatDiscountText({discount_application}) {
      if (discount_application.value_type === "percentage") {
        return `${Number(discount_application.value)}%`;
      } else {
        return `$${Number(discount_application.value)}`;
      }
    },
    productTitle(product) {
      return `${product.product_title}, ${product.variant_title}`;
    },
  },
};
</script>
