/**
 * Native Scroll
 * Checks the scoll position, stores locally and then adds position fixed to the page
 *
 * When unlocked, restores the original scroll position.
 */

export default {
  data: () => {
    return {
      bp: null,
      scrollY: 0,
    };
  },
  methods: {
    lockScreen() {
      this.scrollY = window.scrollY;
      document.body.style.top = `-${this.scrollY}px`;
      document.body.style.position = "fixed";
    },

    unlockScreen() {
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(this.scrollY || "0"));
    },
  },
};
