import {mapActions, mapState} from "vuex";

import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import qs from "query-string";
import uniq from "lodash.uniq";

export default {
  props: {
    productData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedOptions: [],
    };
  },

  mounted() {
    this.getInitialOptionValues();
  },

  watch: {
    selectedVariant(newVal, oldVal) {
      if (this.productData.metafields?.out_of_stock) {
        this.isSoldOut(this.productData.metafields.out_of_stock);
      } else {
        if (newVal.metafields?.out_of_stock) {
          this.isSoldOut(newVal.metafields.out_of_stock);
        } else {
          this.isSoldOut(false);
        }
      }
    },
  },

  computed: {
    ...mapState("product", ["selectedVariant", "quantity", "soldOut"]),

    eligibleVariants() {
      /**
       * Assumes `option1` is the "anchored" option
       * Anchored meaning all other options depend on this to be set.
       *
       */
      return this.getEligibleVariants();
    },

    eligibleOptions() {
      /**
       * Create an array of options that we want to show
       * Create a method that checks if the option in the DOM is in the array of available options
       */
      return uniq(
        this.eligibleVariants
          .flatMap(variant => [
            variant.option1,
            variant.option2,
            variant.option3,
          ])
          .filter(Boolean),
      );
    },

    variantToPurchase() {
      /**
       * 1. Create object for final variant.
       * 2. Create variable for the found variant.
       * * We have to do these separately because if we reassign the variant object it'll be unset for a moment while finding the variant.
       * 3. Once found, deep clone and assign to variant as we are mutating the object.
       * 4. If there are quantity modifications, add them to the variant object.
       */
      let variant = new Object();
      const flatOptions = this.selectedOptions
        .map(option => String(option.value))
        .sort();

      const foundVariant = this.eligibleVariants.find(variant => {
        return isEqual(flatOptions, variant.options.sort());
      });

      if (foundVariant) {
        variant = cloneDeep(foundVariant);
      }

      const metafields = this.productData.metafields;
      const hasQuantityModifications =
        metafields &&
        Array.isArray(metafields.quantity_modifications) &&
        metafields.quantity_modifications.length > 0;

      if (hasQuantityModifications) {
        variant.quantity_modifications = metafields.quantity_modifications;
      }

      return variant;
    },

    productOptions() {
      if (!this.productData) return;
      return this.productData.options;
    },

    stockMessage() {
      if (this.soldOut) return;
      if (!this.selectedVariant?.id) return "";
      if (!this.productData.metafields.stock_notices) return "";

      if (
        this.selectedVariant.quantity <= 0 &&
        this.productData.metafields.stock_notices.out_of_stock
      ) {
        return this.productData.metafields.stock_notices.out_of_stock;
      } else {
        return this.productData.metafields.stock_notices.in_stock;
      }
    },

    productQuantity() {
      return this.quantity;
    },

    quantityModificationOptions() {
      return this.productData.metafields?.quantity_modifications;
    },
  },

  methods: {
    ...mapActions("product", [
      "setQuantity",
      "setQuantityModifications",
      "setProduct",
      "setSoldOut",
    ]),

    getInitialOptionValues() {
      this.setProduct(this.productData);
      /**
       * Check if there is a variant param in the URL
       *
       * - if there is, find that variant
       * - if not, grab the first one.
       */
      const parsed = qs.parse(location.search, {parseNumbers: true});
      let variantIdFromUrl = parsed?.variant;

      const firstAvailableVariant = this.productData.variants.find(variant => {
        if (variantIdFromUrl) {
          return variant.id === Number(variantIdFromUrl);
        } else {
          return variant.available;
        }
      });

      const localOptions = [];
      this.productData.options.forEach(option => {
        localOptions.push({
          type: option.name.toLowerCase(),
          value: firstAvailableVariant[`option${option.position}`],
          position: option.position,
        });
      });

      if (
        Object.prototype.hasOwnProperty.call(
          this.productData.metafields,
          "quantity_modifications",
        ) &&
        this.productData.metafields.quantity_modifications.length > 0
      ) {
        this.setQuantity(
          this.productData.metafields.quantity_modifications[0].quantity,
        );

        this.setQuantityModifications(
          this.productData.metafields.quantity_modifications[0],
        );
      }

      this.selectedOptions = localOptions;
    },

    findVariantsByOptions(evt, type) {
      const selectedOption =
        evt.target.options[evt.target.options.selectedIndex];
      const value = selectedOption.dataset.value;
      const position = selectedOption.dataset.position;
      const optionObject = {
        type,
        value,
        position,
      };

      const index = this.selectedOptions.findIndex(opt => opt.type === type);

      if (index == -1) {
        this.selectedOptions.push(optionObject);
      } else {
        this.selectedOptions.splice(index, 1, optionObject);
      }
    },

    isActiveOption(option) {
      if (!this.selectedOptions) return false;
      return this.selectedOptions.find(opt => isEqual(opt, option));
    },

    isVisibleOption(option) {
      if (!this.selectedOptions) return false;
      /**
       * Option 1 is the "anchor" option that always needs to be skipped because it always needs to be available
       */
      if (option.position === 1) {
        return true;
      } else {
        return this.eligibleOptions.includes(String(option.value));
      }
    },

    quantityModification(evt) {
      const modification = this.quantityModificationOptions.find(
        mod => mod.quantity === Number(evt.target.value),
      );
      this.setQuantity(Number(evt.target.value));
      // this.setQuantityModifications(modification);
    },

    getEligibleVariants() {
      let variantsToSearch = this.productData.variants;

      this.selectedOptions.forEach(option => {
        const foundVariants = variantsToSearch.filter(
          v => v[`option${option.position}`] === option.value,
        );
        variantsToSearch = foundVariants;
      });

      return variantsToSearch;
    },

    isSoldOut(payload) {
      this.setSoldOut(payload);
    },
  },
};
