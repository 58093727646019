<script>
import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";
export default {
  name: "Carousel",
  data: () => {
    return {
      slider: null,
      current: 0,
    };
  },

  mounted() {
    this.slider = new KeenSlider(
      this.$el.parentElement.querySelector("[data-carousel]"),
      {
        initial: this.current,
        slideChanged: s => {
          this.current = s.track.details.rel;
        },
        slides: {
          perView: 1,
          spacing: 0,
        },
      },
    );
  },

  beforeUnmount() {
    if (this.slider) this.slider.destroy();
  },

  methods: {
    moveToSlide(index) {
      this.slider.moveToIdx(index);
    },
  },
  render() {
    return this.$slots.default({
      current: this.current,
      moveToSlide: this.moveToSlide,
    });
  },
};
</script>
