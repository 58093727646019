<template>
  <div class="" @click="openCart">
    {{ cartCount }}
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
export default {
  name: "CartCount",
  props: {},
  data: () => {
    return {};
  },
  computed: {
    ...mapState("cart", {
      cart: "cartData",
      loading: "loading",
    }),
    cartCount() {
      return this.cart ? this.cart.item_count : 0;
    },
  },
  methods: {
    ...mapActions("cart", ["show"]),
    openCart() {
      this.show();
    },
  },
};
</script>
