<script>
import {mapState, mapActions} from "vuex";
import formatMoney from "../../mixins/formatMoney";

export default {
  name: "AddToCart",
  mixins: [formatMoney],
  data: () => ({
    loading: false,
  }),

  props: {
    productData: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.setOutOfStockOnMount();
  },

  computed: {
    ...mapState("product", {
      prod: "product",
      selectedVariant: "selectedVariant",
      quantity: "quantity",
      quantityModification: "quantityModification",
      soldOut: "soldOut",
    }),

    disabled() {
      return (
        this.loading ||
        !this.selectedVariant ||
        !this.selectedVariant?.id ||
        this.soldOut
      );
    },
  },

  methods: {
    ...mapActions("cart", ["addItem"]),

    handleSubmit(e) {
      if (this.isLoading || !this.selectedVariant) return;
      e.preventDefault();

      let propertiesConfig = {};

      if (this.quantityModification?.quantity) {
        propertiesConfig = {
          properties: {
            _quantityModification: this.quantityModification,
          },
        };
      }

      const options = Object.assign(
        {
          id: this.selectedVariant.id,
          quantity: this.quantity,
        },
        propertiesConfig,
      );

      this.loading = true;
      this.addItem(options).then(() => {
        this.loading = false;
      });
    },

    buttonText() {
      const buttonTexts = {
        default: "Add To Cart",
        loading: "Adding...",
        soldOut: "Sold Out",
        selection: "Make A Selection",
      };

      let price = this.selectedVariant.price;

      if (this.quantityModification?.quantity) {
        price = this.selectedVariant.price * this.quantityModification.quantity;
      }

      if (
        Object.keys(this.selectedVariant).length === 0 ||
        !this.selectedVariant?.id
      ) {
        return buttonTexts.selection;
      } else {
        return `${buttonTexts.default} - ${this.formatMoney(
          price * this.quantity,
        )}`;
      }
    },

    setOutOfStockOnMount() {
      /**
       * Computed value is running before mount, so we need a separate method to set the soldOut value on mount
       */
      if (this.prod.metafields?.out_of_stock) {
        this.isSoldOut = this.prod.metafields.out_of_stock;
      }
    },
  },

  render() {
    return this.$slots.default({
      loading: this.loading,
      disabled: this.disabled,
      addToCart: this.handleSubmit,
      isSoldOut: this.soldOut,
      buttonText: this.buttonText,
      selectedVariant: this.selectedVariant,
    });
  },
};
</script>
